"use client";

import { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "next/navigation";
import { POSTS } from "@/helpers";
import Text from "../Text";
import Post from "../Post";
import { PublicTalkContentNavStlye, PublicTalkContentStyle } from "./style";
import { encodeTitle } from "@/helpers/_functions";
import Icon from "../Icon";

const PublicTalkContent = ({ mobileOnly }) => {
  const params = useParams();
  const [tab, setTab] = useState(0);
  const [posts, setPosts] = useState([...POSTS, ...POSTS]);
  const [favorites, setFavorites] = useState(new Set([]));
  const [activePost, setActivePost] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const contentRef = useRef();

  let lastScroll = 0;
  let scrollCount = 0;

  const scrollHandler = (e) => {
    const scrollHeight = contentRef.current.scrollHeight;
    const clientHeight = contentRef.current.clientHeight;

    if (isReady && e.timeStamp - lastScroll > 500) {
      lastScroll = e.timeStamp;
      scrollCount++;
    }
    if (scrollCount >= 3) {
      setActivePost(null);
    }
    if (contentRef.current.scrollTop + clientHeight + 150 >= scrollHeight) {
      setPosts([...posts, ...POSTS]);
    }
  };

  const addToFavourite = (id) => {
    if (favorites.has(id)) {
      favorites.delete(id);
      setFavorites((prev) => {
        prev.delete(id);
        const res = new Set(prev);
        localStorage.setItem("favorites", JSON.stringify(Array.from(res)));
        return res;
      });
      return;
    }
    const res = new Set([...favorites, id]);
    setFavorites(res);
    localStorage.setItem("favorites", JSON.stringify(Array.from(res)));
    setActivePost(null);
  };

  useEffect(() => {
    const res = JSON.parse(localStorage.getItem("favorites") || "[]");
    setFavorites(new Set(res));
  }, []);

  useEffect(() => {
    if (params.title) {
      const el = contentRef.current.querySelector(`[data-title='${params.title}']`);
      if (el) {
        const expand = el.querySelector("[data-expand]");
        if (expand) expand.click();

        const count = el.offsetTop - contentRef.current.scrollTop - 235;
        setTimeout(() => {
          contentRef.current?.scrollBy({
            top: count,
            left: 0,
            behavior: "smooth",
          });
          setTimeout(() => {setIsReady(true);}, 750)
        }, 250)
      }
      setActivePost(params.title);
    }
  }, [params])

  const list = useMemo(() => {
    return tab === 0 ? posts : POSTS.filter((item) => favorites.has(item.id))
  }, [tab, favorites, posts])

  return (
    <>
      <PublicTalkContentNavStlye $mobileOnly={mobileOnly}>
        <Text
          className={`
            nav_text font-avenir-regular 
            ${tab === 0 ? "active" : ""}
          `}
          text={"all"}
          onClick={() => {
            setTab(0);
          }}
        />
        <Text
          className={`nav_text font-avenir-regular ${
            tab === 1 ? "active" : ""
          }`}
          text={favorites.size > 0 ? `favoritesSize` : `favorites`}
          vars={{
            size: favorites.size,
          }}
          onClick={() => {
            setTab(1);
          }}
        />
      </PublicTalkContentNavStlye>
      <PublicTalkContentStyle
        onScroll={scrollHandler} 
        ref={contentRef}
      >
        {list.map((item, i) => (
          <Post
            title={item.title}
            text={item.text}
            id={item.id}
            isInFavourite={favorites.has(item.id)}
            addToFavourite={() => addToFavourite(item.id)}
            key={`${item.id}_${i}`}
            isActive={tab === 0 && i < POSTS.length && activePost === encodeTitle(item.title)}
            setIsActive={(b) => setActivePost(b ? item.id : null)}
          />
        ))}

        {list.length === 0 &&
        <div className={'placeholder'}>
          <Icon name={'bookmark'}/>
          <Text className={'font-avenir-regular p5'} text={'notesPlaceholder'}/>
        </div>}
      </PublicTalkContentStyle>
    </>
  );
};

export default PublicTalkContent;
