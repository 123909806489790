import { withUIContext } from "@/context";

import Icon from "../Icon";
import Text from "../Text";
import PopupShareStyle from "./style";
import { encodeTitle } from "@/helpers/_functions";

const PopupShare = ({ closePopup, id, title, text, showNotification }) => {
  const url = `${location.origin}/yansnotes/${encodeTitle(title)}`;

  const shareLink = () => {
    navigator.clipboard.writeText(url);
    closePopup();
    showNotification({
      type: "success",
      title: "Link copied to clipboard",
    });
  };

  return (
    <PopupShareStyle>
      <a href={`https://telegram.me/share/url?url=${encodeURI(url)}&text=${encodeURI(title)}`} target="_blank" className={"share_button"} onClick={closePopup}>
        <Icon name={"telegram"} />
        <Text className={"font-avenir-regular"} text={"Telegram"} />
      </a>
      <a href={`https://wa.me?text=${encodeURI(url)}`} target="_blank" className={"share_button"} onClick={closePopup}>
        <Icon name={"whatsapp"} />
        <Text className={"font-avenir-regular"} text={"WhatsApp"} />
      </a>
      <a href={`https://www.facebook.com/sharer.php?u=${encodeURI(url)}`} target="_blank" className={"share_button"} onClick={closePopup}>
        <Icon name={"facebook"} />
        <Text className={"font-avenir-regular"} text={"Facebook"} />
      </a>
      <a href={`https://twitter.com/intent/tweet?text=${encodeURI(url)}`} target="_blank" className={"share_button"} onClick={closePopup}>
        <Icon name={"twitter"} />
        <Text className={"font-avenir-regular"} text={"X"} />
      </a>
      <a href={`mailto:?subject=${title}&body=${encodeURI(`${text}\n\n${url}`)}`} target="_blank" className={"share_button"} onClick={closePopup}>
        <Icon name={"mail"} />
        <Text className={"font-avenir-regular"} text={"Email"} />
      </a>
      <div className={"share_button"} onClick={shareLink}>
        <Icon name={"copy"} />
        <Text className={"font-avenir-regular"} text={"copyLink"} />
      </div>
    </PopupShareStyle>
  );
};

export default withUIContext(PopupShare, ["closePopup", "showNotification"]);
