import styled from "styled-components";

export default styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  padding: 24px;
  width: max-content;
  
  .share_button {
    display: flex;
    flex-direction: column;
    gap: 6px;
    align-items: center;
    flex-shrink: 0;
    cursor: pointer;

    &:hover {
      color: var(--sky300)
    }

    &:active {
      color: var(--sky200)
    }
  }

  .font-avenir-regular {
    font-size: 14px;
  }

  .icon {
    width: 24px;
    height: 24px;
    font-size: 24px;
  }

  @media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) { 
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 32px;
    column-gap: normal;
    width: calc(100vw - 32px);
    padding-bottom: 32px;
  }
`;
