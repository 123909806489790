import styled from "styled-components";

export const PublicTalkContentNavStlye = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 210px;
  flex-shrink: 2;

  .nav_text {
    font-size: 18px;
    line-height: 26px;
    cursor: pointer;
  }

  .nav_text.active {
    color: var(--sky200);
    font-weight: 700;
  }

  .fav_wrapper {
    display: flex;
    align-items: center;
  }

  @media (max-width: 1140px) {
    width: 100px;
  }

  ${(props) =>
    props.$mobileOnly &&
    `
    width: 100%;
    flex-direction: row;
    gap: 4px;
    margin-bottom: 24px;

    .nav_text {
      font-size: 14px;
      line-height: 20px;
      padding: 6px 16px;
    }

    .nav_text.active {
      border-bottom: 2px solid var(--sky200);
    }
    
    @media (max-width: 1140px) {
      width: 100%;
    }
  `}

  @media (max-width: 900px) {
    width: 100%;
    flex-direction: row;
    gap: 4px;

    .nav_text {
      font-size: 14px;
      line-height: 20px;
      padding: 6px 16px;
    }

    .nav_text.active {
      border-bottom: 2px solid var(--sky200);
    }
  }
`;

export const PublicTalkContentStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  flex: 1 1;
  padding-bottom: 32px;
  max-height: calc(100vh - 96px - 86px);
  overflow: auto;
  scroll-behavior: smooth;

  .placeholder {
    margin: 0 auto;
    text-align: center;
    height: 100%;
    max-height: 330px;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    gap: 12px;
  }

  .placeholder .icon::before {
    color: #D0D4DC;
    font-size: 62px;
  }

  .placeholder .font-avenir-regular {
    color: #445371;
    font-size: 14px;
  }
`;
